const { REACT_APP_DEPLOY_ENV = 'development' } = process.env;

const UI_HOST_LOCAL = 'http://localhost.nikecloud.com:3000';
const UI_HOST_TEST = 'https://uxf-tools.test.commerce.nikecloud.com';
const UI_HOST_PROD = 'https://uxf-tools.prod.commerce.nikecloud.com';
const API_HOST_DEV = 'http://localhost.nikecloud.com:5000';
const API_HOST = 'https://adminops-int.prod.commerce.nikecloud.com';
const API_PATH_TEST = 'nav-admin-test/v1';
const API_PATH_PROD = 'nav-admin/v1';

const defaults = {
  ENV: REACT_APP_DEPLOY_ENV,
  APP_HOST: UI_HOST_PROD,
  API_BASE_URL: `${API_HOST}/${API_PATH_PROD}`,
  AUTH_STAGE: 'prod',
};

const configurations = {
  development: {
    APP_HOST: UI_HOST_LOCAL,
    API_BASE_URL: `${API_HOST_DEV}/${API_PATH_TEST}`,
    AUTH_STAGE: 'dev',
  },
  test: {
    APP_HOST: UI_HOST_TEST,
    API_BASE_URL: `${API_HOST}/${API_PATH_TEST}`,
    AUTH_STAGE: 'test',
  },
  production: {
    ...defaults,
  },
};

const configuration = {
  ...defaults,
  ...configurations[REACT_APP_DEPLOY_ENV],
  environments: configurations,
};

export default configuration;
