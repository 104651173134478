import { createSelector } from 'reselect';
import { stateKey } from './status-reducer';
import { groupStatusesByCountry } from '../../lib/utils';

const geoCountriesMap = {
  EMEA: [
    'ae',
    'au',
    'bg',
    'ca',
    'ch',
    'cl',
    'eg',
    'hr',
    'il',
    'ma',
    'no',
    'nz',
    'pr',
    'ro',
    'ru',
    'sa',
    'sk',
    'tr',
    'za',
    'at',
    'be',
    'cz',
    'de',
    'dk',
    'es',
    'fi',
    'fr',
    'gb',
    'gr',
    'hu',
    'ie',
    'it',
    'lu',
    'nl',
    'pl',
    'pt',
    'se',
    'si',
  ],
  NA: ['us'],
  APLA: ['id', 'in', 'my', 'ph', 'sg', 'th', 'tw', 'vn', 'br'],
  MX: ['mx'],
  JP: ['jp'],
  KR: ['kr'],
  GC: ['cn'],
};

/**
 * Return the statusReducer state.
 * @param state
 * @returns {{data}|statusReducer}
 */
export const getStatusState = state => state[stateKey];
/**
 * Get the loading state for statuses (if being fetched)
 * @returns {Boolean}
 */
export const isStatusesFetching = createSelector(getStatusState, state => state.isFetching);

export const getStatusesArray = createSelector(getStatusState, state => state.statuses);
export const getStatusesAllRegionsArray = createSelector(
  getStatusState,
  state => state.statusesAllRegions,
);

const getStatusesByGeo = (statuses, geo) => {
  return statuses.filter(
    ({ data: { country } }) => !geo || geoCountriesMap[geo].includes(country.toLowerCase()),
  );
};

/**
 * Return statuses grouped by country code.
 * @returns {Object} { [countryCode]: [ ...{language} ] }
 */
export const getStatuses = createSelector(getStatusState, ({ statuses, geo }) => {
  const filteredStatuses = getStatusesByGeo(statuses, geo);

  return groupStatusesByCountry(filteredStatuses);
});
/**
 * Return a function that returns languages for a country.
 * @returns {Function}
 */
export const getLanguagesForCountry = createSelector(
  getStatuses,
  map => countryCode => map[countryCode],
);

/**
 * Return an array of filters.
 * @returns {Array<Object>}
 */
export const getFilters = createSelector(getStatusState, state =>
  Object.keys(state.statusFilters).map(name => ({ name, value: state.statusFilters[name] })),
);

/**
 * Return grouped statuses with filters applied.
 * @returns {Object}
 */
export const getFilteredStatuses = getStatuses;
