import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import NikeTable from '@nike/nr-vamp-xl-nike-table';
import { string, shape } from 'prop-types';
import { getEnv } from '../../store/application/application-selectors';
import { getStatusForLocale } from '../../lib/api';
import StatusListItemLanguage from '../StatusList/StatusListItemLanguage';
import { getViewUrl } from '../../lib/utils';

/**
 * Describes an individual country/language generation
 * and presents the latest log of generation in a table.
 * @see components/GenerationOverview
 * @param generation {Object}
 * @returns {*}
 */
export default function LocaleOverview({ generation }) {
  const { country, language } = generation.data;
  const env = useSelector(getEnv);
  const [isLoading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);

  // Fetch the individual language data.
  useEffect(() => {
    setLoading(true);
    getStatusForLocale(country, language, env).then(response => {
      setLogs(response.data.generations);
      setLoading(false);
    });
  }, [env, country, language]);

  /**
   * Table headers that map to the table data.
   * @type {*[]}
   */
  const dataKeys = [
    {
      key: 'Timestamp',
      label: 'Time',
      valueRenderer: ({ key, rowData }) => {
        const date = new Date(rowData[key]);
        return date.toISOString();
      },
    },
    {
      key: 'GenerationId',
      label: 'UID',
      valueRenderer: ({ key, rowData }) => {
        const pointer = {
          country: rowData.Country,
          language: rowData.Language,
          current: { id: rowData.GenerationId },
        };
        return (
          <a
            href={getViewUrl({ data: pointer }, env)}
            target="_blank"
            rel="noopener noreferrer"
            className="link-color-secondary"
          >
            {rowData[key]}
          </a>
        );
      },
    },
    {
      key: 'BatchId',
      label: 'Batch ID',
    },
    {
      key: 'Versions',
      label: 'Versions',
      valueRenderer: ({ key, rowData }) => (rowData[key] ? rowData[key].join(', ') : ''),
    },
    {
      key: 'GeneratedBy',
      label: 'Generated By',
    },
  ];

  return (
    <div className="mb6-sm" id={`LogList-${country}-${language}`}>
      <div className="mb2-sm">
        <StatusListItemLanguage pointer={generation} extended />
      </div>
      <NikeTable
        loading={isLoading}
        data={logs}
        data-e2e={`LocaleLogs-${country}-${language}`}
        dataKeys={dataKeys}
        id={`LocaleLogs-${country}-${language}`}
        rowHeight={50}
        tableHeight={200}
      />
    </div>
  );
}

LocaleOverview.propTypes = {
  generation: shape({
    country: string,
    language: string,
    pointer: shape({}),
  }).isRequired,
};
