import React from 'react';
import NikeTable from '@nike/nr-vamp-xl-nike-table';
import { number, shape, string } from 'prop-types';

function LocaleInfoByRegion({ country, language, usEastRecord, usWestRecord, cnRecord }) {
  const data = [
    {
      region: 'us-east-1',
      timestamp: usEastRecord.timestamp,
      generationId: usEastRecord.current.id,
      versions: usEastRecord.current.versions,
    },
    {
      region: 'us-west-1',
      timestamp: usWestRecord.timestamp,
      generationId: usWestRecord.current.id,
      versions: usWestRecord.current.versions,
    },
  ];

  if (cnRecord) {
    data.push({
      region: 'cn-northwest-1',
      timestamp: cnRecord.timestamp,
      generationId: cnRecord.current.id,
      versions: cnRecord.current.versions,
    });
  }

  const dataKeys = [
    {
      key: 'region',
      label: 'Region',
      maxWidth: 200,
    },
    {
      key: 'timestamp',
      label: 'Timestamp',
      valueRenderer: ({ key, rowData }) => {
        const date = new Date(rowData[key]);
        return date.toISOString();
      },
      maxWidth: 400,
    },
    {
      key: 'generationId',
      label: 'GenerationId',
    },
    {
      key: 'versions',
      label: 'Versions',
      valueRenderer: ({ key, rowData }) => (rowData[key] ? rowData[key].join(', ') : ''),
      maxWidth: 200,
    },
  ];

  return (
    <div className="regions-info-table-container">
      <NikeTable
        data={data}
        data-e2e={`LocaleGenerationsMetadata-${country}-${language}`}
        dataKeys={dataKeys}
        id={`LocaleGenerationsMetadata-${country}-${language}`}
        rowHeight={50}
      />
    </div>
  );
}

const localeDataType = shape({
  country: string,
  current: shape({}),
  language: string,
  previous: shape({}),
  timestamp: number,
  updated: string,
});

LocaleInfoByRegion.propTypes = {
  country: string,
  language: string,
  usEastRecord: localeDataType,
  usWestRecord: localeDataType,
  cnRecord: localeDataType,
};

export default LocaleInfoByRegion;
