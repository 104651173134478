import React from 'react';
import { node, string, arrayOf, shape } from 'prop-types';
import { Link } from 'react-router-dom';
import './PageHeader.css';

export default function PageHeader({ children, breadcrumbs }) {
  return (
    <div className="page-header border-bottom mb4-sm">
      <h1 className="display-2 mb2-sm">{children}</h1>
      {breadcrumbs.length && (
        <ul className="breadcrumb-list pb2-sm">
          {breadcrumbs.map(({ label, link }) => (
            <li className="breadcrumb-list-item d-sm-ib" key={label}>
              <Link className="link-color-secondary" to={link}>
                {label}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

PageHeader.defaultProps = {
  breadcrumbs: [],
};

PageHeader.propTypes = {
  children: node.isRequired,
  breadcrumbs: arrayOf(
    shape({
      label: string,
      link: string,
    }),
  ),
};
