import { SET_APPLICATION_LOADING, SET_ENVIRONMENT, NOTIFY, CLOSE_NOTIFICATION } from '../types';
import { getEnv } from './application-selectors';
import { STAGE_PROD, STAGE_TEST, NOTIFY_TYPE_SUCCESS, NOTIFY_DELAY } from '../../constants';

/**
 * Flag loading application on/off.
 * @param bool {Boolean}
 * @returns {{payload: *, type: *}}
 */
export function setLoading(bool) {
  return { type: SET_APPLICATION_LOADING, payload: bool };
}

/**
 * Set the environment.
 * @param env {String} prod|test
 * @returns {{payload: *, type: *}}
 */
export function setEnv(env) {
  let setEnv = env;
  if (![STAGE_PROD, STAGE_TEST].includes(env)) {
    setEnv = STAGE_PROD;
  }
  return { type: SET_ENVIRONMENT, payload: setEnv };
}

/**
 * Open the snackbar tray to show a messgae.
 * @param message
 * @param type
 * @param delay
 * @returns {Function}
 */
export function notify(message, type = NOTIFY_TYPE_SUCCESS, delay = NOTIFY_DELAY) {
  return dispatch => {
    dispatch({ type: NOTIFY, payload: { message, type } });
    setTimeout(() => {
      dispatch({ type: CLOSE_NOTIFICATION });
    }, delay);
  };
}

/**
 * Toggle the environment to prod or test.
 * @returns {Function}
 */
export function toggleEnv() {
  return (dispatch, getState) => {
    const currentEnv = getEnv(getState());
    const nextEnv = currentEnv === STAGE_PROD ? STAGE_TEST : STAGE_PROD;
    dispatch(setEnv(nextEnv));
  };
}

/**
 * Action to call when first initializing the application.
 * @returns {Function}
 */
export function initializeApplication() {
  // TODO nothing to do here yet.
  return dispatch => {
    dispatch(setLoading(false));
  };
}
