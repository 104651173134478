import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { BrowserRouter as Router } from 'react-router-dom';
import { persistStore } from 'redux-persist';
import { NikeThemeProvider } from '@nike/nr-sole-xl-theme-provider';
import { CookiesProvider } from 'react-cookie';
import createStore from '../../store';
import AppFrame from './AppFrame';
import 'animate.css';
import './App.css';

const store = createStore();
const persistor = persistStore(store);

export default function App() {
  return (
    <Provider store={store}>
      <CookiesProvider>
        <PersistGate loading={null} persistor={persistor}>
          <NikeThemeProvider>
            <Router>
              <AppFrame />
            </Router>
          </NikeThemeProvider>
        </PersistGate>
      </CookiesProvider>
    </Provider>
  );
}
