import React from 'react';
import Snackbar from '@nike/nr-vamp-xl-snackbar';
import { useSelector } from 'react-redux';
import { getNotification, isNotificationOpen } from '../../store/application/application-selectors';

export default function NotificationBar() {
  const isOpen = useSelector(isNotificationOpen);
  const notification = useSelector(getNotification);
  return (
    <Snackbar
      data-e2e="NotificationBar"
      message={notification.message}
      open={isOpen}
      snackbarType={notification.type}
    />
  );
}
