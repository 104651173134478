import { createSelector } from 'reselect';
import { stateKey } from './application-reducer';

export const getApplicationState = state => state[stateKey];

export const getEnv = createSelector(getApplicationState, state => state.env);
export const isApplicationLoading = createSelector(getApplicationState, state => state.isLoading);

export const isNotificationOpen = createSelector(
  getApplicationState,
  state => state.notificationOpen,
);

export const getNotification = createSelector(getApplicationState, state => ({
  type: state.notificationType,
  message: state.notificationMessage,
}));
