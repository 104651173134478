import React from 'react';
import { useDispatch } from 'react-redux';
import { DropDownStyled } from '@nike/nike-design-system-components';
import './StatusOverviewSidebar.css';
import { SET_GEO } from '../../store/types';

const filterOptions = [
  { label: 'None', value: null },
  { label: 'ALPA', value: 'APLA' },
  { label: 'EMEA', value: 'EMEA' },
  { label: 'Greater China', value: 'GC' },
  { label: 'North America', value: 'NA' },
  { label: 'MX', value: 'MX' },
  { label: 'JP', value: 'JP' },
  { label: 'KR', value: 'KR' },
];

function StatusOverviewSidebar() {
  const dispatch = useDispatch();

  function onChange(e) {
    const {
      target: { value },
    } = e;
    dispatch({ type: SET_GEO, payload: value });
  }

  return (
    <aside className="l-sidebar border-right p4-sm">
      <DropDownStyled
        name="SelectMenu"
        id="SelectMenu"
        label="Geo"
        defaultValue={filterOptions[0]}
        options={filterOptions}
        onChange={e => onChange(e)}
      />
    </aside>
  );
}

export default StatusOverviewSidebar;
