/**
 * Name of the application.
 * @type {string}
 */
export const APP_NAME = 'Nav';

export const STATUS_COLOR_FRESH = 'status-fresh';
export const STATUS_COLOR_STALE = 'status-stale';
export const STATUS_COLOR_OLD = 'status-old';

/**
 * Time before indicating item is stale. (3 days)
 * @type {number}
 */
export const STATUS_TIME_STALE = 1000 * 60 * 60 * 24 * 3;
/**
 * Time before indicating item is old. (7 days)
 * @type {number}
 */
export const STATUS_TIME_OLD = 1000 * 60 * 60 * 24 * 7;

export const STAGE_PROD = 'prod';
export const STAGE_TEST = 'test';

/**
 * Notification success type.
 * @type {string}
 */
export const NOTIFY_TYPE_SUCCESS = 'success';
export const NOTIFY_TYPE_WARNING = 'warning';
export const NOTIFY_TYPE_ERROR = 'error';
/**
 * Default notification delay before closing.
 * @type {number}
 */
export const NOTIFY_DELAY = 1000 * 6;
/**
 * Length of characters of a UID generation string.
 * So, we don't have to see the whole thing.
 * @type {number}
 */
export const UID_TRUNCATE_LENGTH = 6;
/**
 * Maps stage to node env.
 * @type {{prod: string, dev: string, test: string}}
 */
export const ENV_MAP = {
  dev: 'development',
  test: 'test',
  prod: 'production',
};
