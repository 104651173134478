import React from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bool, number, shape, string } from 'prop-types';
import * as constants from '../../constants';
import config from '../../config';
import ContextMenu from '../ContextMenu/ContextMenu';
import { notify } from '../../store/application/application-actions';
import { getEnv } from '../../store/application/application-selectors';
import { getViewUrl } from '../../lib/utils';

/**
 * Return the status of the item, given the timestamp.
 * @param timestamp {Date}
 * @returns {{color: *, status: string}}
 */
function getStatus(timestamp) {
  const currentTime = Date.now();
  if (currentTime - constants.STATUS_TIME_OLD > timestamp.getTime()) {
    return {
      color: constants.STATUS_COLOR_OLD,
      status: 'Item has not been regenerated in a long time',
    };
  }
  if (currentTime - constants.STATUS_TIME_STALE > timestamp.getTime()) {
    return {
      color: constants.STATUS_COLOR_STALE,
      status: 'Item is starting to get stale',
    };
  }
  return {
    color: constants.STATUS_COLOR_FRESH,
    status: 'Item has been regenerated recently',
  };
}

export default function StatusListItemLanguage({ pointer, extended }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const env = useSelector(getEnv);
  const { country, language } = pointer.data;
  const id = `LogList-${country}-${language}`;
  const href = `/history/${country}#${id}`;
  const hrefPreview = `/preview/${country}/${language}/latest`;
  const timestamp = new Date(pointer.data.timestamp);
  const { color, status } = getStatus(timestamp);
  const day = moment(timestamp).format('h:mma on dddd, MMMM Do');

  /**
   * Opens the Admin API url that returns the view route.
   * @returns void
   */
  function openViewUrl() {
    window.open(`${config.API_BASE_URL}/view/${country}/${language}?env=${env}`, '_blank');
  }

  const options = [
    { label: 'Regenerate', onSelect: () => dispatch(notify('Regeneration not available yet')) },
    { label: 'View Logs', onSelect: () => history.push(href) },
    { label: 'Preview', onSelect: () => history.push(hrefPreview) },
    { label: 'View Current', onSelect: openViewUrl },
  ];
  return (
    <div className=" locale-item">
      <div className="d-sm-flx flx-ai-sm-c">
        <ContextMenu options={options} className="mr2-sm" />
        <div className="locale-item-status mr2-sm pb1-sm">
          <p className={color} title={status}>
            ⬤
          </p>
        </div>
        <div className="locale-item-name mr4-sm pb1-sm">
          {extended && (
            <p className="headline-2 d-sm-ib">
              <span className="mr2-sm">{country}</span>
              <span className="text-color-secondary mr2-sm">/</span>
            </p>
          )}
          <p className="headline-2 d-sm-ib">{language}</p>
        </div>
        <div className="locale-item-timestamp">
          <i className="g72-clock mr1-sm" />
          <p className="product-base text-color-secondary d-sm-ib">{day}</p>
        </div>
      </div>
      {extended && (
        <div>
          <a
            href={getViewUrl(pointer, env)}
            target="_blank"
            rel="noopener noreferrer"
            className="link-color-secondary"
          >
            <strong>{pointer.data.current.id}</strong>
            <span> @ </span>
            <strong>{pointer.data.current.versions.join(', ')}</strong>
          </a>
        </div>
      )}
    </div>
  );
}

StatusListItemLanguage.propTypes = {
  extended: bool,
  pointer: shape({
    timestamp: number,
    country: string,
    language: string,
  }).isRequired,
};

StatusListItemLanguage.defaultProps = {
  extended: false,
};
