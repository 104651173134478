import React from 'react';
import AppBar from '@nike/nr-vamp-xl-app-bar';
import { Link } from 'react-router-dom';
import { Swoosh } from '@nike/svg-icons';
import { useSelector } from 'react-redux';
import EnvironmentOptions from './EnvironmentOptions';
import { isApplicationLoading } from '../../store/application/application-selectors';
import { APP_NAME } from '../../constants';

function AppHeader() {
  // Shows the blue bar on the left to indicate test env.
  const isLoading = useSelector(isApplicationLoading);
  const env = process.env.NODE_ENV === 'production' ? null : 'test';
  return (
    <AppBar
      data-e2e="app-header"
      id="AppHeader"
      loading={isLoading}
      label={APP_NAME}
      apps={[]}
      environment={env}
      logo={<Swoosh style={{ fill: 'white' }} />}
      SwitcherProps={{ isDisabled: true }}
      styleExtension={{
        inkBar: {
          backgroundImage: 'linear-gradient(to right, #3498DB 0, #00d4ff 100%)',
        },
      }}
    >
      <div className="d-sm-flx flx-ai-sm-c">
        <EnvironmentOptions />
        <Link className="text-color-primary-light" to="/support">
          Support
        </Link>
      </div>
    </AppBar>
  );
}

export default AppHeader;
