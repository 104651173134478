import {
  SET_STATUS_FETCHING,
  SET_STATUS_FETCH_ERROR,
  SET_STATUS_FETCH_SUCCESS,
  SET_STATUS_ALL_REGIONS_FETCH_SUCCESS,
  SET_STATUS_FILTER,
  SET_GEO,
} from '../types';

export const stateKey = 'status';

export const initialState = {
  error: null,
  geo: null,
  statuses: [],
  statusesAllRegions: {
    usEast: [],
    usWest: [],
    cn: [],
  },
  statusFilters: {
    searchText: '',
  },
  isFetching: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_GEO:
      return { ...state, geo: action.payload };
    case SET_STATUS_FETCHING:
      return { ...state, isFetching: action.payload };
    case SET_STATUS_FETCH_ERROR:
      return { ...state, error: action.payload };
    case SET_STATUS_FETCH_SUCCESS:
      return { ...state, statuses: action.payload };
    case SET_STATUS_ALL_REGIONS_FETCH_SUCCESS:
      return { ...state, statusesAllRegions: action.payload };
    case SET_STATUS_FILTER:
      return {
        ...state,
        statusFilters: {
          ...state.statusFilters,
          [action.payload.type]: action.payload.value,
        },
      };
    default:
      return state;
  }
}

export const getStatusReducer = () => ({ [stateKey]: reducer });
