import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Toggle from '@nike/nr-vamp-xl-toggle';
import { getEnv, isApplicationLoading } from '../../store/application/application-selectors';
import { setEnv } from '../../store/application/application-actions';
import { STAGE_PROD, STAGE_TEST } from '../../constants';

function EnvironmentOptions() {
  const currentEnv = useSelector(getEnv);
  const isLoading = useSelector(isApplicationLoading);
  const dispatch = useDispatch();

  /**
   * Toggle the env from prod/test.
   * @returns void
   */
  function toggleEnvironment() {
    const env = currentEnv === STAGE_PROD ? STAGE_TEST : STAGE_PROD;
    dispatch(setEnv(env));
  }
  const checked = currentEnv === STAGE_PROD;

  return (
    <div className="text-color-primary-light">
      <Toggle
        disabled={isLoading}
        data-e2e="environment-toggle"
        id="EnvironmentToggle"
        label={currentEnv}
        onChange={toggleEnvironment}
        checked={checked}
        styleExtension={{
          formControlLabel: {
            color: 'white',
          },
        }}
      />
    </div>
  );
}

export default EnvironmentOptions;
