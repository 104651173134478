import React from 'react';
import PageHeader from '../PageHeader/PageHeader';

export default function SupportPage() {
  return (
    <div className="m6-sm">
      <PageHeader
        breadcrumbs={[
          { label: 'Overview', link: '/' },
          { label: 'Support', link: '/support' },
        ]}
      >
        Support
      </PageHeader>
      <div className="d-sm-flx flx-ai-sm-c mb4-sm">
        <a href="https://github.com/nike-internal/dotcom.nav-admin-ui#dotcom-nav-admin-ui">
          <span className="ncss-btn-tertiary-dark underline mr2-sm">README.md</span>
        </a>
      </div>
      <div className="d-sm-flx flx-ai-sm-c flx-wr-sm-w mb4-sm">
        <a href="https://nikedigital.slack.com/messages/CJZCJ2FN0/details/">
          <span className="ncss-btn-tertiary-dark underline mr2-sm">#cic-globalnav-alerts</span>
        </a>
        <span className="body-sm-base">for all production alerts regarding the dotcom-nav.</span>
      </div>
      <div className="d-sm-flx flx-ai-sm-c flx-wr-sm-w mb4-sm">
        <a href="https://nikedigital.slack.com/messages/CJNEYSFB5/details/">
          <span className="ncss-btn-tertiary-dark underline mr2-sm">#cic-globalnav-deploy</span>
        </a>
        <span className="body-sm-base">for information regarding production deploys.</span>
      </div>
      <div className="d-sm-flx flx-ai-sm-c flx-wr-sm-w mb4-sm">
        <a href="https://nikedigital.slack.com/messages/CAB97E44R/details/">
          <span className="ncss-btn-tertiary-dark underline mr2-sm">#cic-globalnav-support</span>
        </a>
        <span className="body-sm-base">
          for any other support or incident related communication.
        </span>
      </div>
    </div>
  );
}
