import React, { useEffect } from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
import AppHeader from './AppHeader';
import { isApplicationLoading } from '../../store/application/application-selectors';
import { initializeApplication } from '../../store/application/application-actions';
import StatusOverview from '../StatusOverview/StatusOverview';
import GenerationsStatistics from '../GenerationsStatistics/GenerationsStatistics';
import GenerationOverview from '../GenerationOverview/GenerationOverview';
import NotificationBar from '../NotificationBar/NotificationBar';
import GenerationPreview from '../GenerationPreview/GenerationPreview';
import SupportPage from '../SupportPage/SupportPage';
import { authenticate } from '../../lib/api';
import webShellUrlParams from '../../data/webShellUrlParams.json';

function getPreviewUrl(q) {
  if (!q.get('preview')) {
    return '/preview/US/en/latest';
  }
  const country = q.get('country').toUpperCase();
  const language = webShellUrlParams[country][q.get('language').toLowerCase()];
  const previewDate = q.get('previewDate') || new Date().toISOString();
  return `/preview/${country}/${language}/latest?previewDate=${previewDate}`;
}

/**
 * Application frame, with authentication.
 * @returns {null|*}
 */
function AppFrame() {
  const dispatch = useDispatch();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const isLoading = useSelector(isApplicationLoading);
  const [cookies] = useCookies(['JSESSIONID']);

  const { JSESSIONID } = cookies;

  const previewUrl = getPreviewUrl(query);

  useEffect(() => {
    authenticate(JSESSIONID).then(() => {
      setTimeout(() => {
        dispatch(initializeApplication());
      }, 500);
    });
  }, [dispatch, JSESSIONID]);

  return (
    <main>
      <AppHeader />
      {isLoading ? null : (
        <div className="page-container">
          <Route path="/" exact component={StatusOverview} />
          <Route path="/stats" exact component={GenerationsStatistics} />
          <Route path="/history/:country" component={GenerationOverview} />
          <Route path="/preview" exact>
            <Redirect to={previewUrl} />
          </Route>
          <Route path="/preview/:country/:language/:version" component={GenerationPreview} />
          <Route path="/support" component={SupportPage} />
        </div>
      )}
      <NotificationBar />
    </main>
  );
}

export default AppFrame;
