import React, { useMemo } from 'react';
import classNames from 'classnames';
import { number, shape, string } from 'prop-types';
import LocaleInfoByRegion from './LocaleInfoByRegion';
import webShellCountryNames from '../../data/webShellCountryNames.json';

function LocaleStats({ usEastRecord, usWestRecord, cnRecord }) {
  const areRegionsUpToDate = useMemo(() => {
    if (usEastRecord.data.current.id !== usWestRecord.data.current.id) {
      return false;
    }
    if (
      cnRecord &&
      (usEastRecord.data.current.id !== cnRecord.data.current.id ||
        usWestRecord.data.current.id !== cnRecord.data.current.id)
    ) {
      return false;
    }
    return true;
  }, [usEastRecord, usWestRecord, cnRecord]);

  return (
    <div className={classNames('p3-sm border-bottom flex')}>
      <span className="link-color-primary locale-title">
        {usEastRecord.data.country}/{usEastRecord.data.language}:
      </span>
      {areRegionsUpToDate ? (
        <div className="up-to-date-message-container">
          <span role="img" aria-label="thumbs-up emojies">
            👍👍👍
          </span>
          <span>
            Generations for country:{' '}
            <strong>{webShellCountryNames[usEastRecord.data.country]}</strong> and language:{' '}
            <strong>{usEastRecord.data.language.toUpperCase()}</strong> are up-to-date in all
            regions
          </span>
          <span role="img" aria-label="thumbs-up emojies">
            👍👍👍
          </span>
        </div>
      ) : (
        <>
          <div className="up-to-date-message-container">
            <span role="img" aria-label="red ex emojies">
              ❌❌❌
            </span>
            <span>
              There are some mismatches in generations for country:{' '}
              <strong>{webShellCountryNames[usEastRecord.data.country]}</strong> and language:{' '}
              <strong>{usEastRecord.data.language.toUpperCase()}</strong>
            </span>
            <span role="img" aria-label="red ex emojies">
              ❌❌❌
            </span>
          </div>
          <LocaleInfoByRegion
            country={usEastRecord.data.country}
            language={usEastRecord.data.language}
            usEastRecord={usEastRecord.data}
            usWestRecord={usWestRecord.data}
            cnRecord={cnRecord?.data}
          />
        </>
      )}
    </div>
  );
}

const localeRecordType = shape({
  elaspedTime: number,
  endTime: number,
  key: string,
  self: string,
  startTime: number,
  uri: string,
  data: shape({
    country: string,
    current: shape({}),
    language: string,
    previous: shape({}),
    timestamp: number,
    updated: string,
  }),
});

LocaleStats.propTypes = {
  usEastRecord: localeRecordType,
  usWestRecord: localeRecordType,
  cnRecord: localeRecordType,
};

export default LocaleStats;
