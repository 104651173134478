import config from '../config';

export const filterMethods = {
  searchText: (value, status) => {
    if (!value.length) return false;
    if (status.country.toLowerCase().indexOf(value) > -1) return true;
    return false;
  },
};

/**
 * Return statuses grouped by country code.
 * @param statuses {Array}
 * @returns {{}}
 */
export function groupStatusesByCountry(statuses) {
  const grouped = {};
  statuses.forEach(pointer => {
    const { country } = pointer.data;
    if (!grouped[country]) {
      grouped[country] = [];
    }
    grouped[country].push(pointer);
  });
  return grouped;
}

/**
 * Return the view URL in the admin api.
 * @param pointer {Object}
 * @param env {string} test|prod
 * @returns {string}
 */
export function getViewUrl(pointer, env = 'prod') {
  const { country, language, current } = pointer.data;
  return `${config.API_BASE_URL}/view/${country}/${language}/${current.id}?env=${env}`;
}
