import { createStore as createReduxStore, applyMiddleware, compose } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import rootReducer from './root-reducer';

export default function createStore() {
  const persistConfig = {
    key: 'root',
    blacklist: ['status'],
    storage,
  };
  const middleware = [thunk];
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const persistedReducer = persistReducer(persistConfig, rootReducer);
  return createReduxStore(persistedReducer, composeEnhancers(applyMiddleware(...middleware)));
}
