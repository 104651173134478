import axios from 'axios';
import { STAGE_PROD } from '../constants';
import config from '../config';

/**
 * OPTIONS request needs credentials as well, to get through CORS
 * @type {boolean}
 */
axios.defaults.withCredentials = true;

export const api = axios.create({
  baseURL: config.API_BASE_URL,
  withCredentials: true,
  responseType: 'json',
});

/**
 * Re-authenticate by reloading the page.
 * @param redirectPath {String}
 */
export function reauth(redirectPath = '/') {
  window.location.replace(
    `${config.API_BASE_URL}/auth?p=${redirectPath}&stage=${config.AUTH_STAGE}`,
  );
}

/**
 * Checks the response from the API.
 * Reauthenticates if necessary, otherwise passes the JSON response.
 * @param response {AxiosResponse}
 * @returns {void|Object}
 */
export function handleApiResponse(response) {
  if (response.headers['content-type'].includes('text/html')) {
    return reauth(window.location.pathname);
  }
  return response.data;
}

/**
 * Request to the API for JSON and handle any common errors we might get.
 * @param url {String}
 * @param env {String} production|development
 * @returns {Promise<AxiosResponse<T>>}
 */
function get(url, env = STAGE_PROD) {
  return api.get(url, { params: { env } }).then(handleApiResponse);
}

/**
 * Authenticates the user when the app starts up.
 * @param jsessionId {String}
 * @returns {Promise<AxiosResponse<T>>|Promise<void>}
 */
export function authenticate(jsessionId) {
  // No session was found, reauthenticate.
  const currentPath = window.location.pathname;
  if (!jsessionId) {
    return Promise.resolve(reauth(currentPath));
  }
  return api
    .get('/auth/session')
    .then(response => {
      // If we received an HTML login page, reauthenticate.
      if (response.headers['content-type'].includes('text/html') || !response.data) {
        return reauth(currentPath);
      }
      // Attempt to check the session value with frame.
      // If they are not the same, reauthenticate.
      if (response.data.data !== jsessionId) {
        return reauth(currentPath);
      }
      // User is authenticated.
      return true;
      // Probably returned CORS error
    })
    .catch(() => reauth(currentPath));
}

export const getAllStatuses = env => get('/status', env);
export const getAllStatusesAllRegions = env => get('/status/all', env);
export const getStatusForCountry = (country, env) => get(`/status/${country}`, env);
export const getStatusForLocale = (country, language, env) =>
  get(`/status/${country}/${language}`, env);

export default api;
