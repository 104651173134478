export const prefix = 'dotcom-nav-admin';

// application
export const SET_ENVIRONMENT = `${prefix}/SET_ENVIRONMENT`;
export const SET_APPLICATION_LOADING = `${prefix}/SET_APPLICATION_LOADING`;
export const CLOSE_NOTIFICATION = `${prefix}/CLOSE_NOTIFICATION`;
export const NOTIFY = `${prefix}/NOTIFY`;

export const SET_GEO = `${prefix}/SET_GEO`;

// status
export const SET_STATUS_FETCHING = `${prefix}/SET_STATUS_FETCHING`;
export const SET_STATUS_FETCH_SUCCESS = `${prefix}/SET_STATUS_FETCH_SUCCESS`;
export const SET_STATUS_FETCH_ERROR = `${prefix}/SET_STATUS_FETCH_ERROR`;
export const SET_STATUS_ALL_REGIONS_FETCH_SUCCESS = `${prefix}/SET_STATUS_ALL_REGIONS_FETCH_SUCCESS`;
export const SET_STATUS_FILTER = `${prefix}/SET_STATUS_FILTER`;

export const SET_HISTORY_DATA_FETCHING = `${prefix}/SET_HISTORY_DATA_FETCHING`;
export const GET_HISTORY_DATA_REQUEST = `${prefix}/GET_HISTORY_DATA_REQUEST`;
export const GET_HISTORY_DATA = `${prefix}/GET_HISTORY_DATA`;
export const TOGGLE_STALE_FILTER = `${prefix}/TOGGLE_STALE_FILTER`;
export const GET_STATUS_ALL_GEOS_REQUEST = `${prefix}/GET_STATUS_ALL_GEOS_REQUEST`;
export const GET_STATUS_ALL_GEOS = `${prefix}/GET_STATUS_ALL_GEOS`;
export const SET_SEARCH_FILTER = `${prefix}/SET_SEARCH_FILTER`;
