import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router';
import Progress from '@nike/nr-vamp-xl-progress';
import { getEnv } from '../../store/application/application-selectors';
import LocaleOverview from '../LocaleOverview/LocaleOverview';
import PageHeader from '../PageHeader/PageHeader';
import { getStatusForCountry } from '../../lib/api';
import webShellCountryNames from '../../data/webShellCountryNames.json';

export default function GenerationOverview() {
  const match = useRouteMatch();
  const env = useSelector(getEnv);
  const { country } = match.params;
  const [isLoading, setLoading] = useState(true);
  const [generations, setGenerations] = useState(null);
  const countryName = webShellCountryNames[country];

  // Get new data if the country or env change.
  // We can get away with storing this in the component state.
  useEffect(() => {
    setLoading(true);
    getStatusForCountry(country, env).then(response => {
      setTimeout(() => {
        setGenerations(response.data);
        setLoading(false);
      }, 500);
    });
  }, [country, env]);

  // Show progress bar if loading.
  if (isLoading) {
    return (
      <div className="full-height-centered">
        <Progress />
      </div>
    );
  }
  // Nothing found.
  if (!generations) {
    return <p>No data.</p>;
  }
  return (
    <div className="p4-sm animated fadeIn faster">
      <PageHeader
        breadcrumbs={[
          { label: 'Overview', link: '/' },
          { label: country, link: `/history/${country}` },
        ]}
      >
        <>
          <span>History for </span>
          <span>{countryName}</span>
          <span className="has-parens ml4-sm">{env}</span>
        </>
      </PageHeader>
      {generations.map(generation => (
        <LocaleOverview generation={generation} key={generation.data.current.id} />
      ))}
    </div>
  );
}
