import React, { useState } from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { arrayOf, shape, string } from 'prop-types';

export default function ContextMenu({ options, className }) {
  const [anchor, setAnchor] = useState(null);
  const openMenu = event => {
    setAnchor(event.currentTarget);
  };

  const closeMenu = selectedOption => event => {
    if (selectedOption) {
      if (selectedOption.onSelect) selectedOption.onSelect(event);
    }
    setAnchor(null);
  };
  return (
    <ClickAwayListener onClickAway={closeMenu()}>
      <div className={className}>
        <IconButton onClick={openMenu}>
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="simple-menu"
          anchorEl={anchor}
          keepMounted
          open={Boolean(anchor)}
          onClose={closeMenu}
        >
          {options.map(option => (
            <MenuItem key={option.label} onClick={closeMenu(option)}>
              {option.label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </ClickAwayListener>
  );
}

ContextMenu.defaultProps = {
  className: null,
};

ContextMenu.propTypes = {
  options: arrayOf(shape({})).isRequired,
  className: string,
};
