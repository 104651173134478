import { SET_ENVIRONMENT, SET_APPLICATION_LOADING, CLOSE_NOTIFICATION, NOTIFY } from '../types';
import { NOTIFY_TYPE_SUCCESS } from '../../constants';

export const stateKey = 'application';

export const initialState = {
  env: 'prod',
  isLoading: true,
  notificationOpen: false,
  notificationMessage: '',
  notificationType: 'success',
};

export function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_APPLICATION_LOADING:
      return { ...state, isLoading: action.payload };
    case SET_ENVIRONMENT:
      return { ...state, env: action.payload };
    case CLOSE_NOTIFICATION:
      return {
        ...state,
        notificationOpen: false,
        notificationMessage: '',
        notificationType: NOTIFY_TYPE_SUCCESS,
      };
    case NOTIFY:
      return {
        ...state,
        notificationOpen: true,
        notificationMessage: action.payload.message,
        notificationType: action.payload.type || NOTIFY_TYPE_SUCCESS,
      };
    default:
      return state;
  }
}

export const getApplicationReducer = () => ({ [stateKey]: reducer });
