import noop from 'lodash/noop';
import { getEnv } from '../application/application-selectors';
import * as api from '../../lib/api';
import {
  SET_STATUS_FETCHING,
  SET_STATUS_FETCH_ERROR,
  SET_STATUS_FETCH_SUCCESS,
  SET_STATUS_ALL_REGIONS_FETCH_SUCCESS,
} from '../types';

export function setStatusFetching(bool) {
  return { type: SET_STATUS_FETCHING, payload: bool };
}
export function setStatusError(error) {
  return { type: SET_STATUS_FETCH_ERROR, payload: error.message };
}
export function setStatusSuccess(data) {
  return { type: SET_STATUS_FETCH_SUCCESS, payload: data };
}
export function setStatusAllRegionsError(error) {
  return { type: SET_STATUS_FETCH_ERROR, payload: error.message };
}
export function setStatusAllRegionsSuccess(data) {
  return { type: SET_STATUS_ALL_REGIONS_FETCH_SUCCESS, payload: data };
}

/**
 * Fetches all statuses and stores them in state, based on the env.
 * @returns {function(*, *): Q.Promise<T>}
 */
export function fetchStatuses(done = noop) {
  return (dispatch, getState) => {
    dispatch(setStatusFetching(true));
    const env = getEnv(getState());
    return api
      .getAllStatuses(env)
      .then(response => {
        dispatch(setStatusSuccess(response.data));
      })
      .catch(error => {
        dispatch(setStatusError(error));
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setStatusFetching(false));
        }, 500);

        done();
      });
  };
}

/**
 * Fetches all statuses from all aws regions and stores them in state, based on the env.
 * @returns {function(*, *): Q.Promise<T>}
 */
export function fetchStatusesAllRegions(done = noop) {
  return (dispatch, getState) => {
    dispatch(setStatusFetching(true));
    const env = getEnv(getState());
    return api
      .getAllStatusesAllRegions(env)
      .then(response => {
        dispatch(setStatusAllRegionsSuccess(response.data));
      })
      .catch(error => {
        dispatch(setStatusAllRegionsError(error));
      })
      .finally(() => {
        setTimeout(() => {
          dispatch(setStatusFetching(false));
        }, 500);

        done();
      });
  };
}
