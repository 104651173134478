import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Progress from '@nike/nr-vamp-xl-progress';
import StatusListItem from './StatusListItem';
import { getFilteredStatuses, isStatusesFetching } from '../../store/status/status-selectors';
import { getEnv } from '../../store/application/application-selectors';
import { fetchStatuses } from '../../store/status/status-actions';

/**
 * Presents a list of all statuses for all locales from /api/status.
 * @see components/StatusOverview
 * @returns {*}
 */
export default function StatusList() {
  const dispatch = useDispatch();
  const env = useSelector(getEnv);
  const isFetching = useSelector(isStatusesFetching);
  const filteredList = useSelector(getFilteredStatuses);

  // When first mounting, or when env changes,
  // Fetch all the statuses for that env.
  useEffect(() => {
    dispatch(fetchStatuses());
  }, [env, dispatch]);

  // Show a progress bar if we're still loading.
  if (isFetching) {
    return (
      <div className="full-height-centered">
        <Progress />
      </div>
    );
  }

  const countryCodes = Object.keys(filteredList);
  return (
    <div className="status-list animated fadeIn faster">
      {countryCodes.map(countryCode => (
        <StatusListItem
          key={countryCode}
          pointers={filteredList[countryCode]}
          country={countryCode}
        />
      ))}
    </div>
  );
}
