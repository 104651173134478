import React from 'react';
import StatusOverviewSidebar from './StatusOverviewSidebar';
import StatusList from '../StatusList/StatusList';

function StatusOverview() {
  return (
    <div className="d-sm-flx flx-wr-sm-nw flx-dir-sm-c flx-dir-md-c flx-dir-lg-r flx-dir-xl-r flx-dir-xxl-r">
      <StatusOverviewSidebar />
      <div className="flx-gro-sm-1">
        <StatusList />
      </div>
    </div>
  );
}

export default StatusOverview;
