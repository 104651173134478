import { combineReducers } from 'redux';

import { getApplicationReducer } from './application/application-reducer';
import { getStatusReducer } from './status/status-reducer';

const rootReducer = combineReducers({
  ...getStatusReducer(),
  ...getApplicationReducer(),
});

export default rootReducer;
