import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { arrayOf, shape, string } from 'prop-types';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import ContextMenu from '../ContextMenu/ContextMenu';
import webShellCountryNames from '../../data/webShellCountryNames.json';
import StatusListItemLanguage from './StatusListItemLanguage';
import { getEnv } from '../../store/application/application-selectors';
import './StatusList.css';

export default function StatusListItem({ country, pointers }) {
  const history = useHistory();
  const env = useSelector(getEnv);
  const href = `/history/${country}`;
  const countryName = webShellCountryNames[country];
  return (
    <div
      className={classNames('status-list-item p4-sm border-bottom', { 'is-test': env === 'test' })}
    >
      <div className="d-sm-flx flx-ai-sm-c">
        <ContextMenu
          className="d-sm-ib mr2-sm"
          options={[{ label: 'View History', onSelect: () => history.push(href) }]}
        />
        <Link to={href} className="status-list-item-link display-3 link-color-primary">
          <>
            <span>{country}</span>
            <span> - </span>
            <span>{countryName}</span>
          </>
        </Link>
      </div>
      <ul className="locale-list pl6-sm">
        {pointers.map(pointer => {
          const key = `${pointer.data.country}/${pointer.data.language}`;
          return (
            <li className="locale-list-item" key={key}>
              <StatusListItemLanguage pointer={pointer} />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

StatusListItem.propTypes = {
  country: string.isRequired,
  pointers: arrayOf(shape({})).isRequired,
};
