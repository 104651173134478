import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Progress from '@nike/nr-vamp-xl-progress';
import LocaleStats from './LocaleStats';
import {
  getStatusesAllRegionsArray,
  isStatusesFetching,
} from '../../store/status/status-selectors';
import { getEnv } from '../../store/application/application-selectors';
import { fetchStatusesAllRegions } from '../../store/status/status-actions';
import './GenerationsStatistics.css';

function GenerationsStatistics() {
  const dispatch = useDispatch();
  const env = useSelector(getEnv);
  const isFetching = useSelector(isStatusesFetching);
  const statusesAllRegionsData = useSelector(getStatusesAllRegionsArray);

  // When first mounting, or when env changes,
  // Fetch all the statuses from all regions for that env.
  useEffect(() => {
    dispatch(fetchStatusesAllRegions());
  }, [env, dispatch]);

  // Show a progress bar if we're still loading.
  if (isFetching) {
    return (
      <div className="full-height-centered">
        <Progress />
      </div>
    );
  }

  const { usEast, usWest, cn } = statusesAllRegionsData;

  return (
    <div className="d-sm-flx flx-dir-sm-c flx-dir-md-c">
      {usEast.map(usEastRecord => {
        const usWestRecord = usWest.find(
          el =>
            el.data.country === usEastRecord.data.country &&
            el.data.language === usEastRecord.data.language,
        );
        const cnRecord = cn.find(
          el =>
            el.data.country === usEastRecord.data.country &&
            el.data.language === usEastRecord.data.language,
        );
        return (
          <LocaleStats
            key={`${usEastRecord.data.country}/${usEastRecord.data.language}`}
            usEastRecord={usEastRecord}
            usWestRecord={usWestRecord}
            cnRecord={cnRecord}
          />
        );
      })}
    </div>
  );
}

export default GenerationsStatistics;
