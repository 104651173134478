import React from 'react';
import { useSelector } from 'react-redux';
import { useRouteMatch, useLocation } from 'react-router';
import { getEnv } from '../../store/application/application-selectors';
import config from '../../config';

export default function GenerationPreview() {
  const match = useRouteMatch();
  const env = useSelector(getEnv);
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  const { country, language, version = 'latest' } = match.params;
  const previewDate = query.get('previewDate');

  const PREVIEW_PATH =
    `${config.API_BASE_URL}/preview/${country}/${language}/${version}?env=${env}`.concat(
      previewDate ? `&previewDate=${previewDate}` : '',
    );

  return (
    <div className="full-height-centered border-black">
      <iframe
        src={PREVIEW_PATH}
        sandbox="allow-scripts allow-same-origin"
        title="Global Nav Preview"
        className="u-full-width u-full-height"
      />
    </div>
  );
}
